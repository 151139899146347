<template>
  <div class="ui-px-6">
    <div class="ui-py-3 ui-py-8 c-text-h2 ui-text-left" data-cy="lista-de-albuns">Lista de Álbuns</div>
    <div class="c-row ui-mb-4 ui-items-center">
      <div class="c-col ui-flex-grow ">
        <c-search-bar placeholder="Buscar por título" v-model="filter" />
      </div>
      <div class="c-col ui-flex-grow-0">
        <c-button color="primary" class="add-button" @click="createAlbum">
          Criar novo álbum
          <icon name="add" class="c-icon--right" />
        </c-button>
      </div>
    </div>
    <div class="c-row">
      <div class="c-col">
        <c-table class="ui-w-full">
          <template slot="head">
            <c-table-row>
              <c-table-head class="ui-w-16"> </c-table-head>
              <c-table-head> Título do Álbum </c-table-head>
              <c-table-head> Criado em </c-table-head>
              <c-table-head class="no-wrap"> Atualizado em </c-table-head>
              <c-table-head> Descrição </c-table-head>
              <c-table-head> Publicado </c-table-head>
              <c-table-head> Ações </c-table-head>
            </c-table-row>
          </template>

          <c-table-row v-for="album in items" :key="album.id">
            <c-table-data>
              <img
                v-show="!loading"
                no-default-spinner
                :src="album.image | getImage('100')"
                :placeholder-src="album.image | getImage('100')"
              />
              <c-skeleton-image width="2rem" height="2rem" v-show="loading" />
            </c-table-data>
            <c-table-data>
              <div v-show="!loading">{{ album.title }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data>
              <div v-show="!loading">{{ album.created_at }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data>
              <div v-show="!loading">{{ album.updated_at }}</div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
            <c-table-data>
              <tooltip v-show="!loading">
                <template v-slot:container>
                  {{ album.description | truncateText }}
                </template>
                <span> {{ album.description }}</span>
              </tooltip>
              <c-skeleton-block v-show="loading" />
            </c-table-data>

            <c-table-data>
              <c-toggle
                v-show="!loading"
                dense
                v-model="album.is_published"
                color="primary"
                @input="toggle(album.id, album.is_published)"
              />
              <c-skeleton-block v-show="loading" />
            </c-table-data>

            <c-table-data>
              <div class="ui-flex" v-show="!loading">
                <c-link flat color="primary" @click="editAlbum(album.id)">
                  <icon
                    name="edit"
                    outline
                    class="c-icon--right"
                    color="primary"
                  />
                </c-link>
                <c-link flat color="red" @click="deleteAlbumDialog(album)">
                  <icon
                    name="trash"
                    height="20px"
                    outline
                    class="c-icon--right"
                    color="red"
                  />
                </c-link>
              </div>
              <c-skeleton-block v-show="loading" />
            </c-table-data>
          </c-table-row>
        </c-table>
      </div>
    </div>
    <pagination
      :page="pagination.page"
      :total-pages="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
      :rowsPerPage="pagination.rowsPerPage"
      @changePage="changePage"
      @changePerPage="changePerPage"
    />

    <modal :show="deleteDialog" @close="deleteDialog = false">
      <template v-slot:title>Deseja remover este álbum?</template>
      <template v-slot:description>{{ selectedAlbum.title }}</template>
      <template v-slot:actions>
        <div class="ui-flex">
          <div class="ui-flex-1">
            <c-link
              class="ui-w-full ui-h-full c-text-gray-300"
              component="button"
              @click="deleteDialog = false"
              >Não, cancelar</c-link
            >
          </div>
          <div class="ui-flex-1">
            <c-button
              class="ui-w-full"
              color="primary"
              @click="deleteAlbum(selectedAlbum.id)"
              >Sim, remover</c-button
            >
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>


<script>
// import { date } from 'quasar'
import {
  CButton,
  CSearchBar,
  CToggle,
  Icon,
  CTable,
  CTableRow,
  CTableHead,
  CTableData,
  CLink,
  CSkeletonImage,
  CSkeletonBlock,
} from "@estrategiahq/coruja-web-ui";
import Tooltip from "../../components/ToolTip.vue";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination.vue";
export default {
  components: {
    CButton,
    CSearchBar,
    CToggle,
    Icon,
    CTable,
    CTableRow,
    CTableHead,
    CTableData,
    CLink,
    CSkeletonImage,
    CSkeletonBlock,
    Tooltip,
    Modal,
    Pagination,
  },
  data() {
    return {
      items: [
        { image: "", title: "", created_at: "", updated_at: "" },
        { image: "", title: "", created_at: "", updated_at: "" },
        { image: "", title: "", created_at: "", updated_at: "" },
        { image: "", title: "", created_at: "", updated_at: "" },
        { image: "", title: "", created_at: "", updated_at: "" },
        { image: "", title: "", created_at: "", updated_at: "" },
      ],
      lastSort: "",
      filter: "",
      clickedLabel: {
        name: "",
        status: true,
      },
      deleteDialog: false,
      loading: true,
      pagination: {
        page: 1,
        prevPage: 0,
        rowsPerPage: 20,
        rowsNumber: 10,
        info: {},
      },
      timeout: null,
    };
  },
  mounted() {
    this.fetchAlbums({
      pagination: this.pagination,
      filter: this.filter,
    });
  },
  filters: {
    truncateText(text) {
      if (!text) {
        return ""
      }
      const maxLength = 60;
      if (text.length < maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    getImage(imageUrl, size) {
      return imageUrl?.replace("raw", size) || null;
    },
  },
  watch: {
    async filter(newFilter) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () =>
          this.fetchAlbums({
            pagination: this.pagination,
            filter: newFilter,
            sortBy: this.sortBy,
          }),
        300
      );
    },
  },
  methods: {
    createAlbum() {
      this.$router.push({
        name: "createAlbum",
      });
    },
    editAlbum(id) {
      this.$router.push({
        name: "editAlbum",
        params: { id, edit: true },
      });
    },
    deleteAlbumDialog(album) {
      this.deleteDialog = true;
      this.selectedAlbum = album;
    },
    customSort({ label, field, sortable }) {
      if (sortable) {
        this.clickedLabel.name = label;
        if (this.clickedLabel.name === label) {
          this.clickedLabel.status = !this.clickedLabel.status;
        } else {
          this.clickedLabel.status = false;
        }
        this.fetchAlbums({
          pagination: this.pagination,
          filter: this.filter,
          sortBy: field,
        });
      }
    },
    async fetchAlbums({ pagination, filter, sortBy }, hideLoading) {
      if (!hideLoading) {
        this.loading = true;
      }

      let search = `?page=${pagination.page}&per_page=${pagination.rowsPerPage}`;
      search += !filter.length ? "" : `&search=(title[icontains]:'${filter}')`;

      if (sortBy) {
        let term;
        if (sortBy === this.lastSort) {
          term = sortBy.includes("-") ? sortBy.replace("-", "") : `-${sortBy}`;
        } else {
          term = sortBy;
        }
        this.lastSort = term;
        search += `&sort=${term}`;
      }

      let result;
      try {
        result = await this.$s.album.fetchAlbums(search);
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
      this.items = result.data.map((item) => ({
        ...item,
        created_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at)
        ),
        updated_at: Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
          Date.parse(item.created_at) > Date.parse(item.updated_at)
            ? Date.parse(item.created_at)
            : Date.parse(item.updated_at)
        ),
      }));
      this.pagination.page = result.meta.page;
      this.pagination.rowsPerPage = result.meta.per_page;
      this.pagination.rowsNumber = result.meta.total;
    },
    async deleteAlbum() {
      await this.$s.album.deleteAlbum(this.selectedAlbum.id);
      this.$emit("toast", {
        type: "success",
        text: "Álbum removido com sucesso",
        timeout: 3000,
        position: "top",
      });

      this.fetchAlbums({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.deleteDialog = false;
    },
    async toggle(id, isPublished) {
      if (isPublished) {
        await this.$s.album.publishAlbum(id);
        this.$emit("toast", {
          text: "Álbum publicado com sucesso",
          position: "top",
          timeout: 3000,
        });
      } else {
        await this.$s.album.unpublishAlbum(id);
        this.$emit("toast", {
          text: "Álbum despublicado com sucesso",
          position: "top",
          timeout: 3000,
        });
      }
      this.fetchAlbums({
        pagination: this.pagination,
        filter: this.filter,
      },true);
    },
    async changePerPage(value) {
      this.pagination = {
        page: 1,
        prevPage: 0,
        rowsPerPage: value,
      };
      await this.fetchAlbums({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    async changePage(page) {
      this.pagination.page = page;
      await this.fetchAlbums({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
  },
};
</script>

<style scoped>
.add-button {
  width: 229px;
}
.no-wrap {
  white-space: nowrap;
}
</style>